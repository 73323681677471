import { declOfNum } from "./index";
import { DashboardTimeUnitEnum, DeviceAssignmentsEnum, EventAssignmentsEnum, MetricsEnum, PersonAssignmentsEnum, TimeAssignmentsEnum, VehicleAssignmentsEnum, } from "@dwclient/domain";
import RU_CONTROL_RESOURCES from "./control/ru";
import ruEditorResources from "./editors/ru";
import ruEnumResources from "./enums/ru";
import panelsRu from "./panels/ru";
const ruResources = {
    zoneCard: {
        zoneTimeUtilization: "Категория",
        zoneBusinessRules: "Бизнес-правила",
    },
    controls: {
        deployments: {
            title: "Выберите должностную позицию",
            cancel: "Отмена",
            select: "Выбрать",
            organization: "Организация",
            department: "Подразделение",
            deployment: "Должностная позиция",
            deploymentsNotExist: "Не найдено",
        },
    },
    cameraCard: {
        code: "ID",
        zone: "Геозона",
        objectCount: "Сотрудники в поле зрения",
        showOnMap: {
            title: "Показать на карте",
            button: "Показать",
        },
    },
    userProfile: {
        avatarDialog: "Изменение фотографии",
        avatarDialogSave: "Сохранить",
    },
    frames: {
        beaconSignals: {
            filter: {
                tagId: "Поиск по наименованию и UID",
                showInstanceId: "Показать идентификатор",
            },
            table: {
                title: "Сигналы маяков",
                columns: {
                    name: "Название",
                    instanceId: "Идентификатор",
                    occurred: "Время сигнала",
                    rssi: "RSSI, дБм",
                    distance: "Расстояние до маяка, м",
                },
            },
        },
        createZone: {
            doneMessage: "Геозона успешно создана!",
        },
    },
    table: {
        selected: "Выбрано строк",
        noData: "Нет записей",
        paging: {
            info: ({ from, count, to }) => `${from}-${to}. Всего ${count}`,
            rowsPerPage: "Записей на странице",
            showAll: "Показать все",
        },
    },
    panels: panelsRu,
    audit: {
        table: {
            columns: {
                occurred: "Дата и время",
                eventType: "Тип события",
                ip: "IP-адрес",
                initiator: "Инициатор",
                objectId: "ID oбъекта",
                browserVersion: "Версия браузера",
                comment: "Описание",
            },
            filter: {
                placeholder: "Поиск",
                hintTop: "по инициатору, типу события",
                hintBottom: null,
                name: "Название",
                login: "Инициатор",
                eventType: "Тип события",
                fieldHolder: "Искать в поле",
                buttons: {
                    clear: "Очистить фильтр",
                },
            },
        },
    },
    loginForm: {
        username: "Логин",
        password: "Пароль",
        submit: "Войти",
        knownErrorHttpCodes: {
            401: "Неверная комбинация логина и пароля.",
            404: "Сервис аутентификации недоступен. Повторите попытку позже.",
        },
        knownErrorCodes: {
            "unknown_error": "Произошла неизвестная ошибка на стороне сервиса аутентификации.",
            "invalid_grant": "Неверная комбинация логина и пароля.",
        },
        helperText: "Не удаётся войти? Написать в",
    },
    editors: {
        ...ruEditorResources,
        placement: {
            helperText: "Поставьте точку на карте, чтобы отобразить модель",
        },
        sensor: {
            title: "Датчик",
            edit: "Настроить",
            form: {
                submit: "Сохранить",
                cancel: "Отменить",
                zoneDialog: "Родительская геозона",
                type: "Тип датчика",
                typeDialog: "Тип датчика",
                lostChangesDialog: {
                    title: "Вы уверены, что хотите прервать создание?",
                    yesButton: "Прервать",
                    noButton: "Нет",
                },
                discardAllChangesDialog: {
                    title: "Вы уверены, что хотите сбросить все изменения?",
                    yesButton: "Сбросить",
                    noButton: "Нет",
                },
                fields: {
                    type: "Тип датчика",
                    name: "Название",
                    vendorId: "Sensor UID",
                    zoneId: "Геозона",
                    externalSystemId: "Внешняя система",
                },
            },
            deleteDialog: {
                title: "Вы уверены, что хотите архивировать датчик?",
                yesButton: "Архивировать",
                noButton: "Отмена",
            },
            restoreDialog: {
                title: "Вы уверены, что хотите восстановить датчик?",
                yesButton: "Восстановить",
                noButton: "Отмена",
            },
        },
        camera: {
            title: "Камера",
            edit: "Настроить",
            form: {
                zoneId: "Геозона",
                model: "Модель",
                modelDialog: "3D модель устройства",
                name: "Название",
                rtspUrl: "Основной поток",
                lowResolutionRtspUrl: "Поток низкого разрешения",
                code: "ID камеры",
                orientationBlock: "Направление нулевой установки",
                heading: "Азимут",
                pitch: "Возвышение",
                angleBlock: "Углы обзора",
                horizontalAngle: "По горизонтали",
                verticalAngle: "По вертикали",
                heightAboveFloor: "Высота от уровня пола",
                cancel: "Отменить",
                submit: "Сохранить",
                zoneDialog: "Родительская геозона",
                resolution: "Разрешение",
                faceRecognition: "Распознавание лиц",
            },
            deleteCameraDialog: {
                noButton: "Нет",
                deleteButton: "Архивировать",
                title: "Вы уверены что хотите архивировать устройство?",
            },
            restoreCameraDialog: {
                noButton: "Нет",
                restoreButton: "Восстановить",
                title: "Вы уверены что хотите восстановить устройство?",
            },
        },
        building: {
            closeBtn: "Отменить",
            deleteLevelDialog: "Вы уверены, что хотите удалить файл модели? ",
            groupNameNotDefined: "Новый объект",
            levelNameNotDefined: "Без названия",
            parent: "Родитель",
            saveBtn: "Сохранить",
            title: "Многоуровневый объект",
            addLevelBtn: "Добавить уровень",
            lostChangesDialog: "Вы уверены, что хотите прервать создание?",
            lostChangesDialogYes: "Прервать",
            lostChangesDialogNo: "Нет",
            deleteLevelButton: "Удалить уровень",
            editLevelButton: "Изменить файл модели",
            deleteBuildingDialog: {
                noButton: "Нет",
                deleteButton: "Удалить",
                title: "Вы уверены, что хотите удалить объект?",
            },
            zoneDialog: "Родительская геозона",
        },
        buildingSelector: {
            createBtn: "Создать",
            title: "Объекты",
        },
        zoneSelector: {
            title: "Геозоны",
            create: "Создать новую",
        },
        zone: {
            title: "Настройки геозоны",
            form: {
                name: "Название",
                height: "Высота геозоны, м",
                altitude: "Высота от нулевой отметки, м",
                parent: "Родитель",
                outline: "Контур",
                createViewPoint: "Создать новую точку обзора",
                viewPoint: "Точка обзора",
                zoneBusinessRule: "Бизнес-правила",
                zoneTimeUtilization: "Категория",
                cancel: "Отмена",
                submit: "Сохранить",
                zoneDialog: "Родительская геозона",
                useCurrentViewPoint: "Использовать текущее положение камеры",
                validation: {
                    polygon: {
                        notClosed: "Контур должен быть замкнут.",
                        notInsideParent: "Все точки контура должны находиться в границах родительской геозоны.",
                        notIntersectOthers: (zonesNames) => "Контур не должен иметь пересечений с другими геозонами (" + zonesNames.join(" ,") + ")",
                        cantContainsChildren: "Все точки дочерних геозон должны находиться в границах изменяемого контура.",
                    },
                    height: {
                        notInsideParent: (min, max) => `Верхняя точка должна быть в пределах родительской геозоны. ${min} и ${max}.`,
                        invalidValue: (min, max) => `Высота должна быть в пределах ${min} и ${max}.`,
                        cantContainsChildren: "Верхняя точка не может быть ниже верхних точек дочерних геозон.",
                    },
                    altitude: {
                        invalidValue: (min, max) => `Высота должна быть в пределах ${min} и ${max}.`,
                        notInsideParent: (min, max) => `Высота от нулевой отметки должна быть в пределах родительской геозоны. ${min} и ${max}.`,
                        cantContainsChildren: "Высота от нулевой отметки не может быть выше высот дочерних геозон.",
                    },
                },
            },
            deleteZoneDialog: {
                noButton: "Нет",
                deleteButton: "Удалить",
                title: "Вы уверены, что хотите удалить геозону?",
            },
            outline: {
                previousVersion: {
                    title: "Показать предыдущую версию",
                    empty: "Пусто",
                    copyButton: "Копировать контур и высоту",
                },
            },
        },
        zoneModel: {
            clearZoneButton: "Удалить",
            clearZoneText: "Удалите геозону, если нужно выбрать другую",
            createZone: "Добавить контур",
            selectZone: "Копировать контур",
            selectZoneDialog: {
                placeholder: "Выбрать из списка",
                selectButton: "Выбрать",
                title: "Копировать контур",
            },
            tabs: {
                parameters: "Параметры",
                zone: "Геозона",
            },
            createOutline: "Создать контур",
            changeOutline: "Контур",
            deleteGeofenceContourDialog: {
                noButton: "Нет",
                deleteButton: "Удалить",
                title: "Вы уверены, что хотите удалить контур геозоны?",
            },
            saveOutlineTooltip: "Сохранить контур",
            closeTooltip: "Закрыть",
            deletePointTooltip: "Удалить точку",
            discardAllOutlineChangesDialog: {
                title: "Вы уверены, что хотите прервать разметку? ",
                noButton: "Нет",
                discardButton: "Прервать",
            },
            discardAllChangesDialog: {
                title: "Вы уверены, что хотите сбросить все изменения?",
                noButton: "Нет",
                discardButton: "Сбросить ",
            },
            undoTooltip: "Отменить",
            redoTooltip: "Вернуть",
            viewFromAboveTooltip: "Вид сверху",
            secantPlaneTooltip: "Секущая плоскость",
            createViewPoint: "Добавить точку обзора",
            selectViewPoint: "Выбрать точку обзора",
            selectViewPointDialog: {
                selectButton: "Выбрать",
                title: "Точка обзора",
            },
            viewPointNameField: "Название",
            viewPointForm: {
                searchPlaceholder: "Поиск по точкам обзора",
                emptyTitle: "Выберите точку обзора",
            },
            cleanViewPointDialog: {
                title: "Очистить точку обзора",
                noButton: "Нет",
                yesButton: "Очистить",
            },
            polygonFirstPointHelper: "Поставьте первую точку.\nИспользуя точки других геозон, вы обеспечиваете точное покрытие без пробелов",
            polygonSecondPointHelper: "Поставьте вторую точку",
            polygonThirdPointHelper: "Поставьте третью точку",
            polygonLastPointHelper: "Замкните контур\n или продолжайте ставить точки",
        },
        beacon: {
            form: {
                name: "Название",
                mac: "MAC-адрес",
                txPower: "Tx power, dB",
                ibeaconUUID: "UUID",
                ibeaconMajor: "Major",
                ibeaconMinor: "Minor",
                eddystoneNamespace: "Namespace",
                eddystoneInstance: "Instance ID",
                close: "Отменить",
                submit: "Сохранить",
                eddystoneEnabled: "",
                ibeaconEnabled: "",
            },
            lostChangesDialog: "Вы уверены, что хотите прервать создание?",
            lostChangesDialogYes: "Прервать",
            lostChangesDialogNo: "Нет",
            deleteBeaconDialog: {
                noButton: "Нет",
                deleteButton: "Удалить",
                title: "Вы уверены что хотите удалить устройство?",
            },
            edit: "Настроить",
        },
        operations: {
            edit: "Редактировать",
            delete: "Удалить",
            change: "Изменить",
            copy: "Копировать",
            archive: "Архивировать",
            restore: "Восстановить",
        },
        toolbar: {
            routeMap: "Карты обхода",
            objects: "Объекты",
            geofences: "Геозоны",
            devices: "Устройства",
            locations: "Локация",
            viewPoints: "Точка обзора",
        },
        common: {
            notSelected: "Не выбрана",
        },
    },
    files: {
        preview: "Открыть превью",
        uploadButton: ["Перетащите файлы или ", "нажмите", ", чтобы добавить"],
        selectedFiles: "Выбранные файлы",
        selectFiles: "Добавить",
        deleteMessage: "Вы уверены, что хотите удалить выбранный файл?",
        download: "Скачать",
        rename: "Переименовать",
    },
    folders: {
        deleteMessage: "Вы уверены, что хотите удалить папку с ее содержимым?",
        foldersTitle: "Папки",
        defaultFolderName: "Основная",
        folderNamePlaceholder: "Введите название",
        emptyFolderName: "Без названия",
        deleteTooltip: "Удалить папку",
        editTooltip: "Изменить название",
        addTooltip: "Новая папка",
    },
    mediaGallery: {
        notSupported: "Файл не поддерживается",
    },
    empty: "Пусто",
    metrics: {
        [MetricsEnum.Vehicle]: "Техника",
        [MetricsEnum.Person]: "Сотрудники",
        [MetricsEnum.Time]: "Время",
        [MetricsEnum.Event]: "События",
        [MetricsEnum.Device]: "Устройства",
    },
    analytics: {
        reports: {
            tabs: {
                generated: "Построенные",
                regular: "Регулярные",
                allRegular: "Все регулярные",
            },
            ok: "Запрос отчета произошел успешно",
            error: "Извините, во время операции произошла ошибка. Повторите попытку позже",
            form: {
                submit: "Построить",
                save: "Сохранить",
                fields: {
                    email: "Укажите Email",
                    date_from: "Дата начала",
                    date_to: "Дата окончания",
                    time_from: "Время начала",
                    time_to: "Время окончания",
                    timezone: "Часовой пояс",
                    report_code: "Выберите тип отчета",
                    format_code: "Формат",
                    route_map: "Маршрутная карта",
                    shift: "Смена",
                    is_regular: "Получать регулярно",
                    generation_time: "Время формирования",
                    generation_period: "Период формирования",
                    delivery_channel: "Канал доставки",
                    schedule_every: "Каждые",
                    schedule_periodicity: "Интервал",
                    schedule_start_date: "Начиная с",
                    organization: "Организация",
                    department: "Подразделение",
                    shift_cycle: "Цикл смен",
                    persons: "Сотрудники",
                    selectPerson: "Выберите сотрудника",
                    incidentsLog: {
                        trackables: "Участники",
                        selectTrackables: "Выберите участников",
                        incidentState: "Статусы",
                        selectIncidentState: "Выберите статусы события",
                        incidentPriority: "Приоритеты",
                        selectIncidentPriority: "Выберите приоритеты события",
                        incidentType: "Типы",
                        selectIncidentType: "Выберите типы события",
                        organization: "Организации",
                        selectOrganization: "Выберите организацию",
                        tag: "Теги",
                        selectTag: "Выберите теги",
                        geofence: {
                            title: "Геозоны",
                            add: "Добавить геозону",
                            noItems: "Выберите геозону",
                            itemsSelected: "Выбрано",
                            selectGeofenceTitle: "Выберите геозону",
                        },
                    },
                    dayOfWeek: {
                        1: "Пн",
                        2: "Вт",
                        3: "Ср",
                        4: "Чт",
                        5: "Пт",
                        6: "Сб",
                        0: "Вс",
                    },
                },
                create: "Создать новый",
                newReport: "Новый отчет",
                editReport: "Параметры отчета",
                title: "Построить отчет",
                validation: {
                    email_not_correct: "Введен некорректный Email",
                    required: "Обязательное поле",
                    personHasTags: "Вы не можете уволить сотрудника, пока за ним закреплено оборудование.",
                    personHasUser: "Вы не можете уволить сотрудника, потому что он является пользователем системы.",
                    fromDateAfterToDate: "Дата начала не должна быть больше даты окончания",
                    fromTimeAfterToTime: "Время начала должно быть меньше времени окончания",
                },
                deliveryChannelNotDefined: 'Невозможно выбрать канал доставки, так как в Вашем профиле не указаны Телефон и E-mail. Сформированные отчёты будут отображаться в списке "Построенные".',
                deliveryChannel: 'Сформированные отчёты будут отображаться в списке "Построенные" и доставлены по каналам: {channels}',
                multipleSubscription: {
                    default: 'Сформированные отчёты будут отображаться в списке "Построенные" для выбранных сотрудников.',
                    noEmail: (names) => "Для этих сотрудников невозможно доставлять отчеты на электронную почту, так как в их профилях не указан E-mail: " +
                        names.toString() +
                        ".",
                    noPhone: (names) => "Для этих сотрудников невозможно доставлять отчеты в Telegram, так как в их профилях нет номера телефона: " +
                        names.toString() +
                        ".",
                    noTelegram: (names) => "Для этих сотрудников невозможно доставлять отчеты в Telegram, так как они не подписаны на канал рассылки в Telegram: " +
                        names.toString() +
                        ".",
                },
                personalSubscription: {
                    default: 'Сформированные отчёты будут отображаться в списке "Построенные".',
                    noEmail: 'Невозможно выбрать канал доставки "E-mail", так как в Вашем профиле не указан E-mail.',
                    noPhone: 'Невозможно выбрать канал доставки "Telegram", так как в Вашем профиле не указан Телефон. ',
                    noTelegram: 'Невозможно выбрать канал доставки "Telegram", так как в Вы не подписаны на канал рассылки в Telegram.',
                },
            },
            table: {
                header: {
                    date: "Время создания",
                    type: "Тип",
                    status: "Статус",
                    title: "Название отчёта",
                },
                row: {
                    status: {
                        finished: "Готов",
                        inProgress: "В процессе",
                        failed: "Ошибка",
                    },
                    title: {
                        from: "c",
                        to: "до",
                    },
                },
            },
            regular: {
                filter: {
                    hintTop: "по сотрудникам,",
                    hintBottom: "названиям отчётов",
                    name: "Название отчёта",
                    recipient: "Получатель",
                },
                table: {
                    header: {
                        creationDate: "Дата создания",
                        title: "Название отчета",
                        formatCode: "Тип",
                        deliveryChannel: "Как доставляется",
                        generationPeriod: "Период",
                        schedule: "Расписание",
                        scheduleGenerationTime: "Время доставки",
                        recipient: "Получатель",
                    },
                    schedule: "Каждые {scheduleEvery} {schedulePeriodicity},\nначиная с {scheduleStartDate}",
                },
                deleteDialog: {
                    ok: "Удалить",
                    close: "Отмена",
                    title: "Вы уверены что хотите удалить подписку на получение отчета?",
                },
                menu: {
                    editButton: "Редактировать",
                    deleteButton: "Удалить",
                },
            },
            helper: "Идёт загрузка данных",
            download: "Скачать",
        },
        dashboard: {
            vehicle_connection_status: {
                name: "Статус подключения",
                description: "Показывает количество активных (онлайн) и неактивных (оффлайн) единиц техники",
                total: "Всего",
            },
            widgetFilterPresenter: {
                positions: {
                    byOne: "должность",
                    byManyElement: "должности",
                },
                zoneBusinessRules: {
                    byOne: "категория бизнес-правил",
                    byManyElement: "категорий бизнес-правил",
                },
                zoneTimeUtilization: {
                    byOne: "категория зоны",
                    byManyElement: "категории зон",
                },
                organizations: {
                    byOne: "организация",
                    byManyElement: "организаций",
                },
                persons: {
                    byOne: "сотрудник",
                    byManyElement: "сотрудников",
                },
                more: "ещё",
            },
            dataRequestTime: "Статус на",
            widgetAssignments: {
                [MetricsEnum.Person]: {
                    [PersonAssignmentsEnum.Zones]: "Зонам",
                    [PersonAssignmentsEnum.ZoneVisits]: "Нахождению в зоне",
                    [PersonAssignmentsEnum.PositionGPN]: "Должностям",
                    [PersonAssignmentsEnum.ZoneCategories]: "Категориям зон",
                    [PersonAssignmentsEnum.Organizations]: "Организациям",
                },
                [MetricsEnum.Time]: {
                    [TimeAssignmentsEnum.ZoneCategories]: "Категориям зон",
                    [TimeAssignmentsEnum.Geofences]: "Геозонам",
                },
                [MetricsEnum.Event]: {
                    [EventAssignmentsEnum.Statuses]: "Статусам",
                    [EventAssignmentsEnum.Persons]: "Сотрудникам",
                    [EventAssignmentsEnum.Zones]: "Зонам",
                    [EventAssignmentsEnum.Priorities]: "Приоритетам",
                    [EventAssignmentsEnum.Types]: "Типам",
                },
                [MetricsEnum.Device]: {
                    [DeviceAssignmentsEnum.ConnectionStatuses]: "Статус подключения",
                    [DeviceAssignmentsEnum.Detector]: "Показатели телеметрии",
                },
                [MetricsEnum.Vehicle]: {
                    [VehicleAssignmentsEnum.ConnectionStatuses]: "Статус подключения",
                },
            },
            templateDialog: {
                size: "Размерность",
                title: "Добавить новый виджет",
                assignments: "Распределение по",
                presentation: "Предпросмотр",
                description: "Описание",
                selectButton: "Перейти к настройке",
            },
            emptyTitleDashboard: "Доска без названия",
            enterTitle: "Введите название",
            createBoard: "Создать доску",
            selectBoard: "Выбрать доску",
            editBoard: "Настроить доску",
            sharedBoard: "Общая",
            changeBoardName: "Изменить название",
            saveEditor: "Сохранить доску",
            closeEditor: "Отменить изменения",
            noData: "Нет данных",
            noDataShort: "Н/Д",
            emptyZoneState: "Без категории",
            noSignal: "Нет сигнала",
            addWidget: "Добавить виджет",
            deleteWidgetDialog: {
                title: "Вы уверены, что хотите удалить виджет?",
                closeButton: "Отменить",
                okButton: "Удалить",
            },
            deleteDashboardDialog: {
                title: "Выбранная доска будет удалена. Продолжить?",
                button: "Удалить доску",
            },
            period: {
                [DashboardTimeUnitEnum.TODAY]: "Сегодня",
                [DashboardTimeUnitEnum.MONTH]: "Месяц",
                [DashboardTimeUnitEnum.WEEK]: "Неделя",
                [DashboardTimeUnitEnum.YEAR]: "Год",
                [DashboardTimeUnitEnum.YESTERDAY]: "Вчера",
            },
            widgetForm: {
                title: "Настройка виджета",
                fields: {
                    chartType: "Тип графика",
                    code: "Шаблон",
                    title: "Название",
                    positions: "Должность",
                    department: "Департамент",
                    appointments: "Должности",
                    departments: "Департаменты",
                    organizations: "Организации",
                    from: "Дата начала",
                    persons: "Сотрудники",
                    zoneBusinessRules: "Категории бизнес-правил",
                    zoneTimeUtilization: "Категория зоны",
                    to: "Дата окончания",
                    zones: "Зоны",
                    zone: "Зона",
                    zoneSelector: "Выбор зоны",
                    incidentTypes: "Типы событий",
                    incidentStatuses: "Статусы событий",
                    incidentPriorities: "Приоритеты событий",
                    topCount: "Количество топ-нарушителей",
                    showNoSignal: "Показать отсутствие сигнала",
                    pointsNum: "Количество точек",
                    groupingFields: "Поля для группировки",
                    errors: {
                        emptyZone: "Необходимо выбрать зону",
                    },
                },
                groups: {
                    common: "Общее",
                    period: "Выбрать период",
                    parameters: "Фильтрация",
                    shift: "Смена",
                    selectShift: "Смена не выбрана",
                },
                buttons: {
                    cancel: "Отмена",
                    submit: "Сохранить",
                },
            },
            legend: {
                appointments: (count) => `По ${count} должностям`,
                organizations: (count) => `По ${count} организациям`,
            },
            timeSpentByZoneStates: {
                name: "Время нахождения по категориям зон",
                description: "Показывает суммарное время, проведенное сотрудниками в зонах по категориям: рабочая зона, зона отдыха, зона вне территории и др.",
            },
            timeSpentByGeofences: {
                name: "Время нахождения по геозонам",
                description: "Показывает суммарное время, проведенное сотрудниками в выбранной геозоне, с группировкой по дочерним геозонам.",
            },
            totalEmployeesCount: {
                name: "Численность сотрудников в зоне",
                description: 'Показывает кол-во уникальных сотрудников, что сейчас находятся в выбранной "родительской" зоне с группировкой по дочерним зонам.',
                parentZone: "Родительская зона",
                zoneStructure: "Структура зоны",
                childZones: "Дочерних зон:",
                noData: "В выбранной зоне отсутствуют сотрудники",
                otherZones: (num) => "Остальные (" + num + ") ",
            },
            employeesAttendance: {
                name: "Посещаемость зоны",
                description: "Показывает посещаемость сотрудниками зоны во времени. Посещаемость - количество посетивших зону уникальных сотрудников за интервал графика.",
            },
            tagTemperature: {
                name: "Температура воздуха",
            },
            tagPulse: {
                name: "ЧСС",
            },
            incidentsCountByStatus: {
                name: "Событий по статусам",
                description: "Показывает число открытых и закрытых Событий.",
                open: "Открытых",
                closed: "Закрытых",
                total: "Всего",
            },
            incidentCountByZones: {
                name: "События по зонам",
                description: "Показывает число зафиксированных Событий, по зонам.",
            },
            incidentsTopByObject: {
                name: "События по участникам",
                description: "Показывает число зафиксированных Событий, по участникам.",
            },
            coTwoData: {
                name: "Данные с газовых сигнализаторов",
                description: "Отображает список датчиков в системе с данными об уровне CO2",
                threshold: "Норма",
                okValue: "Все показания в пределах нормы",
                notOkValue: "Есть отклонения от нормы",
            },
            totalEmployeesInZoneForPeriod: {
                name: "Посещаемость зоны по должностям",
                description: "Показывает количество уникальных посещений сотрудниками выбранной зоны, в сравнении с целевым количеством сотрудников в организации, по должностям.",
                quantityInZone: "На площадке",
                quantityInOrganization: "В организации",
            },
            employeesInZoneByOrganization: {
                name: "Численность сотрудников по организациям",
                description: "Показывает актуальное и целевое количество сотрудников на площадке, по организациям.",
                fact: "Факт",
                plan: "План",
            },
            connectionStatus: {
                name: "Статус подключения",
                description: "Показывает количество активных (онлайн) и неактивных (оффлайн) устройств",
                totalDevices: "Всего устройств",
            },
            employeesByZoneCategories: {
                name: "Численность сотрудников по категориям зон",
                description: "Показывает актуальное распределение сотрудников по категориям зон: рабочая зона, зона отдыха, зона вне территории и др.",
            },
        },
    },
    indicators: {
        signalLost: "Потеря сигнала",
    },
    heatmap: {
        title: "Построить тепловую карту",
        submit: "Построить",
        fields: {
            date_from: "Дата начала",
            date_to: "Дата окончания",
            time_from: "Время начала",
            time_to: "Время окончания",
            trackable: "Отслеживаемый сотрудник",
        },
        validation: {
            endEarlierThanStartHelpText: "Время окончания должно быть больше времени начала",
        },
        change: "Изменить параметры",
    },
    track: {
        title: "Построить маршрут",
        route_start: "Начало маршрута",
        route_end: "Окончание маршрута в ",
        submit: "Построить",
        fields: {
            date_from: "Дата начала",
            date_to: "Дата окончания",
            time_from: "Время начала",
            time_to: "Время окончания",
            trackable: "Отслеживаемый сотрудник",
        },
        validation: {
            endEarlierThanStartHelpText: "Время окончания должно быть больше времени начала",
            maxPeriod: (max) => `Период должен быть меньше ${max} часов`,
        },
        change: "Изменить параметры",
    },
    filterInfo: {
        from: "Дата начала",
        to: "Дата окончания",
        metrics: "Метрика",
        person: "Сотрудник",
        organization: "Организация",
        persons: "Сотрудников",
        organizations: "Организаций",
        zoneVisitsByPerson: "Посещаемость геозон, по сотрудникам",
        zoneVisitsByOrganization: "Посещаемость геозон, по организациям",
        change: "Изменить параметры",
    },
    close: "Закрыть",
    settings: {
        events: "События",
        quickFilter: "Быстрый фильтр",
        quickFilterNotSelected: "Не выбран",
        notifications: "Уведомления",
        mapSettings: "Настройки карты",
        mapItems: "Элементы карты",
        zones: "Зоны",
        zoneModels: "Модели",
        trackables: "Отслеживаемые объекты",
        appearance: "Внешний вид",
        theme: "Тема",
        cesiumShowShadows: "Показывать тени",
        cesiumTerrainShadowMode: "Режим отображения тени на местности",
        cesiumExplicitRender: "Рендеринг по запросу",
        cesiumShowFps: "Счетчик FPS",
        cesiumShowFullScreenBtn: 'Показывать кнопку "Full screen"',
        cesiumShowHomeBtn: 'Показывать кнопку "View Home"',
        cesiumShowNavigationHelpBtn: 'Показывать кнопку "Navigation Instructions"',
        cesiumAnisotropicFilter: "Anisotropic Filter",
        cesiumFxaa: "FXAA",
        cesiumHDR: "HDR",
        lang: "Язык",
        showBuildings: "Показывать здания",
        showZones: "Показывать зоны",
        showFloors: "Показывать этажи",
        showZoneModels: "Показывать модели",
        showBeacons: "Показывать маяки",
        showCameras: "Показывать камеры",
        showSensors: "Показывать датчики",
        showTrackables: "Показывать отслеживаемые объекты",
        showPersons: "Показывать сотрудников",
        showProtectionEquipment: "Показывать СИЗ",
        showIndicators: "Показывать индикаторы",
        coordinatePicker: "Показывать информацию о координатах",
        visibilityDistance: "Дальность прорисовки",
        labelsVisibilityDistance: "Дальность отображения наименований",
        defaultZone: "Зона по умолчанию",
        personStatPanel: "Панель в карточке сотрудника",
        deviceIssue: "Выдача устройств",
        deviceHostUrl: "Адрес хоста для устройств",
        deviceIssuingTimeout: "Таймаут до автоматической выдачи",
        multipleDeviceIssuing: "Выдавать несколько устройств",
        detectionRadius: "Радиус обнаружения",
        details: "Детали",
        wearableDevices: "Носимые устройства",
        certificates: "Сертификаты",
    },
    submit: "Готово",
    open: "Открыть",
    build: "Построить",
    time: "Время",
    errors: {
        error403: {
            title: "Ошибка 403. Доступ Запрещен",
            description: "У вас нет прав доступа к этой странице. Пожалуйста, попробуйте снова или обратитесь к Вашему системному администратору",
        },
        runtime: "Непредвиденная ошибка",
    },
    loading: "Загрузка",
    online: "В сети",
    login: "Вход в систему",
    logout: {
        button: "Выход",
        dialog: {
            text: "Вы действительно хотите выйти?",
            ok: "Выход",
            cancel: "Закрыть",
        },
    },
    waitingForData: "Ожидание данных",
    loadingError: "Ошибка при загрузке страницы",
    select: {
        multi: {
            counter: "Выбрано",
        },
        showAll: "Показать всё",
        hideAll: "Скрыть всё",
        noOptionsText: "Нет доступных вариантов",
    },
    units: {
        co2: "мг/м3",
        distance: {
            meters: "м.",
        },
        fileSize: {
            0: "Б",
            1: "КБ",
            2: "МБ",
            3: "ГБ",
        },
        heartRate: "уд/мин",
        time: {
            hour: "ч",
            minute: "м",
            second: "с",
        },
        humanHour: "чч",
    },
    eventCard: {
        history: {
            columns: {
                name: "Наименование события",
                occurred: "Время создания",
                initiator: "Инициатор",
            },
        },
        messages: {
            successCopied: "Ссылка скопирована",
            copiedWithError: "Не удалось скопировать ссылку",
            exportedWithError: "Не удалось экспортировать карточку",
            successExported: "Запрос на экспорт карточки отправлен",
        },
        toolbar: {
            backToLog: "К журналу",
            openIncident: "Переоткрыть",
            closeIncident: "Завершить",
            report: {
                title: "Новый отчёт",
                button: "Сформировать отчёт",
                submit: "Построить",
                buttonTitle: "Выгрузка событий",
            },
            export: {
                button: "Экспортировать",
            },
        },
        comments: {
            columns: {
                text: "Комментарий",
                creationTime: "Время создания",
                author: "Оператор",
            },
            commentsForm: {
                comment: {
                    label: "Текст комментария",
                    placeholder: "Введите текст комментария",
                },
                submit: "Добавить",
            },
            addCommentBtn: "Добавить комментарий",
        },
        panel: {
            photo: "Фотографии",
            video: "Видео",
            info: "Сведения",
            attachment: "Файлы",
            trackables: "Участники",
            undefinedTrackables: "Не определено",
            comments: "Комментарии",
            media: "Медиа",
            cameras: "Камеры",
            snapshot: "Снимок карты",
            history: "История",
        },
    },
    eventLog: {
        tags: {
            title: "Теги",
            input: "Найти тег",
        },
        attachment: {
            download: "Скачать",
            view: "Просмотр",
        },
        columns: {
            source: "Источник",
            probability: "Индекс вероятности",
            endDateTime: "Время окончания",
            occured: "Время создания",
            priority: "Приоритет",
            trackables: "Участник(и)",
            zone: "Геозона",
            type: "Тип события",
            tags: "Теги",
            state: "Статус",
            details: "Детали",
            moreTip: "Дополнительно",
            configDialog: {
                close: "Закрыть",
                title: "Настроить колонки",
            },
        },
        filter: {
            quickfilter: "Быстрый фильтр",
            priority: "Приоритет",
            state: "Статус",
            date_from: "Дата начала",
            time_from: "Время начала",
            date_to: "Дата окончания",
            time_to: "Время окончания",
            choosePeriod: "Выбрать период",
            noDataShort: "Н/Д",
            emptySelect: "Пусто",
            search: {
                kinds: {
                    incidentType: "Тип события",
                    zone: "Локация",
                    org: "Организация",
                    tags: "Теги",
                    trackable: "Участник",
                    other: "",
                },
                placeholder: {
                    search: "Поиск",
                    hintTop: "участник, локация, тип события,",
                    hintBottom: "организация участника, тег",
                },
            },
            buttons: {
                clearSelection: "Очистить выбор",
                clearFilter: "Очистить фильтр",
                columns: "Настроить колонки",
            },
        },
        history: "История",
        copyUrl: "Скопировать ссылку",
        export: "Экспортировать",
        prevEvent: "Предыдущее событие",
        nextEvent: "Следующее событие",
        prevEventShort: "Пред.",
        nextEventShort: "След.",
    },
    control: RU_CONTROL_RESOURCES,
    dayOfWeekPicker: {
        all: "все",
    },
    dateTimePicker: {
        invalidDateMessage: "Неверный формат",
        okLabel: "Ок",
        cancelLabel: "Отмена",
        clearLabel: "Очистить",
        dateFormat: "DD.MM.YYYY",
        timeFormat: "HH:mm",
        ampm: false,
    },
    "locale": "ru",
    "not_defined": "Не определено",
    "not_defined_plural": "Не определены",
    "zone-hierarchy": {
        "0": "Здание",
        "1": "Этаж",
        "2": "Помещение",
    },
    "snapshotCard": {
        "head": "Информация о событии",
        "zone": "Место события",
        "trackables": "Участники события",
    },
    "mapController": {
        "home": "Весь комплекс",
        "0-level": "Уровень комплекса",
        "1-level": "Уровень здания",
        "2-level": "Уровень этажа",
        "3-level": "Уровень помещения",
    },
    "listEvent": {
        "fullListEvents": "Полный список в журнале",
        "participants": " участников",
        "noParticipants": "Нет участников",
    },
    "navigation": {
        map: {
            title: "Карта",
            view: "Карта",
            heatmap: "Тепловая карта",
            track: "Маршруты",
            timeMachine: "История",
            snapshot: "Снимок события",
            showNorth: "Показать на север",
            gotoViewpoint: "Вернуться к точке обзора",
            close: "Закрыть",
            save: "Сохранить",
        },
        editor: "Редактор",
        editors: {
            anchor: "Размещение локации",
            anchorForm: {
                toolbar: {
                    placement: "Размещение",
                    movement: "Перемещение",
                    rotation: "Поворот",
                    ruler: "Линейка",
                },
                next: "Продолжить",
                back: "Назад",
                submit: "Сохранить",
                validation: {
                    required: "Обязательное поле",
                    rangeLatitude: "Допустим ввод только значений от -90 до +90",
                    rangeLongitude: "Допустим ввод только значений от -180 до 180",
                },
                selectForm: {
                    title: "Выбор локации",
                    placeholder: "Выберите локацию",
                    create: "Создать новую",
                },
                changeForm: {
                    title: "Локация",
                    externalSystem: "Внешняя система",
                    externalId: "Внешний идентификатор",
                    name: "Название",
                    params: "Дополнительные параметры",
                },
                rotation: {
                    title: "Поворот",
                    placeholder: "Поворот",
                },
                disposition: {
                    title: "Размещение",
                    altitude: "Высота",
                    latitude: "Широта",
                    longitude: "Долгота",
                    zones: "Зоны",
                },
            },
            building: {
                validation: {
                    zone: "Укажите геозону",
                    polygon: "Укажите уровни и геозоны",
                },
            },
            zoneModel: "Размещение модели",
            viewpoint: "Точка обзора",
            zoneModelForm: {
                next: "Продолжить",
                back: "Назад",
                submit: "Сохранить",
                validation: {
                    required: "Обязательное поле",
                    incorrectFormat: "Некорректный формат файла",
                    fileNotFound: "Указанный файл не найден",
                    rangeLatitude: "Допустим ввод только значений от -90 до +90",
                    rangeLongitude: "Допустим ввод только значений от -180 до 180",
                },
                selectForm: {
                    title: "Выбор модели",
                    placeholder: "Выберите модель",
                    create: "Создать новую",
                },
                changeForm: {
                    title: "Модель",
                    name: "Название",
                    model: "Файл модели",
                    zone: "Зона",
                    parent: "Родительская модель",
                },
                rotation: {
                    title: "Поворот",
                    placeholder: "Поровот",
                },
                disposition: {
                    title: "Размещение",
                    altitude: "Высота",
                    latitude: "Широта",
                    longitude: "Долгота",
                },
                scale: {
                    title: "Масштаб",
                    placeholder: "Масштаб",
                },
            },
            devices: "Устройства",
            devicesPanel: {
                installed: "установлено",
                create: "Создать",
                search: "Поиск по названию",
                deviceType: {
                    beacon: "Маяки и шлюзы",
                    camera: "Камеры наблюдения",
                    sensor: "Датчики",
                },
                beaconPanel: {
                    newObjectPanelTitle: "Новый BLE маяк",
                    editObjectPanelTitle: "BLE маяк",
                },
                cameraPanel: {
                    newObjectPanelTitle: "Новая камера",
                    editObjectPanelTitle: "Камера",
                },
                sensorPanel: {
                    newObjectPanelTitle: "Новый датчик",
                    editObjectPanelTitle: "Датчик",
                },
            },
        },
        userProfile: "Профиль сотрудника",
        "eventLog": "События",
        analyticsPanel: "Аналитика и отчеты",
        eventCard: "Карточка события",
        "admin": "Управление",
        "analytics": "Аналитика",
        "reports": "Отчеты",
        "businessRulesConstructor": "Бизнес-правила 2.0",
        "businessRules": "Бизнес-правила",
        layers: "Слои",
        data: "Данные",
        control: "Справочники",
        "selector": {
            "placeholder": "Выберите элемент...",
            "groups": {
                "zones": "Все зоны",
                "trackables": "Все сотрудники",
                "viewPoints": "Все точки обзора",
                organizations: "Все организации",
                cameras: "Все камеры",
            },
        },
        breadCrumbs: {
            selectNext: "Выбрать объект/зону",
            selectZone: "Выбрать зону",
            search: "Поиск по зонам",
        },
        audit: "Журнал аудита",
    },
    "model": {
        "Zone": {
            "title": "Название",
        },
        "Building": {
            "address": "Адрес",
            "floorsCount": "этажей",
        },
        beacon: "Маяк",
        sensor: "Датчик",
    },
    layers: {
        panel: {
            title: "Подложка карты",
            tabs: {
                base: "Базовая",
                additional: "Дополнительная",
            },
        },
        name: "Наименование",
        source: "Источник",
        enable: "Вкл/выкл",
        createDate: "Дата загрузки",
        version: "Версия",
        show: "Показывать в селекторе",
        url: "Ссылка",
        files: "Файлы",
        untitled: "Без названия",
        type: {
            title: "Тип слоя",
            base: "Базовый",
            additional: "Дополнительный",
        },
        editor: {
            title: "Слои",
            external: "Внешние",
            local: "Локальные",
            addLayer: "Добавить новый",
            addVersion: "Добавить версию",
            editButton: "Свойства слоя",
            removeButton: "Удалить слой",
            newLayer: "Новый слой",
            editLayer: "Редактировать слой",
            deleteMessage: "Вы действительно хотите удалить слой?",
        },
        form: {
            add: "Добавить",
            update: "Сохранить",
        },
    },
    validation: {
        required: "Поле должно быть заполнено",
        mustBeLink: "Поле должно содержать ссылку",
        mustBeEmail: "Поле должно содержать корректный email адрес",
        mustBePhoneNumber: "Поле должно содержать корректный телефонный номер",
        mustBeColorCode: "Поле должно содержать корректный HEX-код цвета (#FFF или #FFFFFF)",
        invalidCharacters: "Поле содержит недопустимые символы",
    },
    "zone-common-info": {
        "title": "Общая информация",
        "editor": "Редактор",
    },
    "zoneCommonInfo": {
        "trackables": {
            "title": "Сотрудники",
        },
    },
    "viewpointCreator": {
        "title": "Создать смотровую точку?",
        "viewpointNameInputLabel": "Название",
        "confirmationButtonContent": "Да",
        "cancelButtonContent": "Нет",
    },
    timeMachineController: {
        done: "Завершено",
        title: "Выберите период",
        date_from: "Дата начала",
        date_to: "Дата окончания",
        time_from: "Время начала",
        time_to: "Время окончания",
        startTime: "Время начала",
        endTime: "Время окончания",
        playButtomHint: "Воспроизвести",
        pauseButtonHint: "Пауза",
        stopButtonHint: "Стоп",
        notFilledValueHelpText: "Заполните значение",
        endEarlierThanStartHelpText: "Время окончания должно быть больше времени начала",
        endLaterThatCurrentTimeHelpText: "Время окончания должно быть меньше текущего времени",
        startLaterThatCurrentTimeHelpText: "Время начала должно быть меньше текущего времени",
        emptyPeriodHelpText: "Нет данных за выбранный период",
        speed: "Скорость",
        currentTime: "Текущее время",
    },
    "tag-card": {
        temperature: {
            title: "Температура воздуха",
            units: "°C",
        },
        pulse: {
            title: "ЧСС",
            units: "Уд/мин",
        },
    },
    organizationCard: {
        inWorkingAreas: "В рабочих зонах",
        atTheSite: "Сейчас на объекте",
        allPersons: "Всего в организации",
        persons: "Сотрудники",
        departments: "Подразделения",
        contacts: {
            title: "Контакты",
            address: "Адрес",
            email: "Почта",
            website: "Сайт",
            phone: "Телефон",
        },
        color: {
            title: "Цветовой индикатор",
        },
    },
    "person-card": {
        certificates: "Аттестации",
        healthStatus: "Состояние здоровья",
        heartRate: "ЧСС",
        openProfile: "Открыть профиль",
        showOnMap: {
            title: "Показать на карте",
            button: "Показать",
        },
        devices: {
            title: "Устройства",
            chargeLevel: "Заряд",
            signal: "Сигнал",
            used: "Используется",
            notUsed: "Отсутствует",
        },
        objectTracking: "Отслеживание",
        protectionEquipment: "СИЗ",
    },
    "person-card.title": "Сотрудник",
    "person-card.tel": "Тел.",
    "trackable-card.title": "Отслеживаемый объект",
    certificateCard: {
        notValid: "Не актуальна",
        validWithWarning: "Актуальна менее 3-х месяцев",
        valid: "Актуальна",
        issuedBy: "Выдан",
        till: "до",
    },
    workPermitCard: {
        infoTitle: "Информация",
        description: "Описание",
        registrationDate: "Дата рег.",
        registrationNumber: "Рег. номер",
        createdAt: "Создано",
    },
    "zone-common-info.editor": "Редактор",
    formatters: {
        position: {
            latitude: "с.ш.",
            longitude: "в.д.",
            altitude: "высота",
            altitudeUnitMeasure: "м",
        },
        meters: "м",
    },
    organizations: {
        panel: {
            title: "Организации",
        },
        declension: {
            nominative: (number) => declOfNum(number, ["организация", "организации", "организаций"]),
            genitive: (number) => declOfNum(number, ["организации", "организаций", "организаций"]),
        },
    },
    employees: {
        declension: {
            nominative: (number) => declOfNum(number, ["сотрудник", "сотрудника", "сотрудников"]),
            genitive: (number) => declOfNum(number, ["сотрудника", "сотрудников", "сотрудников"]),
        },
    },
    zones: {
        declension: {
            nominative: (number) => declOfNum(number, ["зона", "зоны", "зон"]),
            genitive: (number) => declOfNum(number, ["зоны", "зон", "зон"]),
        },
    },
    appointments: {
        declension: {
            nominative: (number) => declOfNum(number, ["должность", "должности", "должностей"]),
            genitive: (number) => declOfNum(number, ["должности", "должностей", "должностей"]),
        },
    },
    deployments: {
        declension: {
            nominative: (number) => declOfNum(number, ["должностная позиция", "должностные позиции", "должностных позиций"]),
            genitive: (number) => declOfNum(number, ["должностой позиции", "должностных позиций", "должностных позиций"]),
        },
    },
    incidents: {
        "statusbar": {
            "alert": "Тревога",
            "attention": "Внимание",
        },
        "panel": {
            media: "Медиа",
            persons: "Сотрудники",
            cameras: "Камеры",
            events: "События",
            eventType: "Приоритет",
            status: "Статус",
            openingDate: "Время создания",
            location: "Геозона",
            showMore: "Подробнее",
            mapSnapshot: "Снимок карты",
            show: "Показать",
            history: "История",
        },
    },
    noOrganization: "Без организации",
    noAppointment: "Без должности",
    enum: ruEnumResources,
    pretext: {
        by: "по",
        and: "и",
    },
    colors: {
        "#18A4E1": "Голубой",
        "#52AEA3": "Пассат",
        "#017F8D": "Голубая лагуна",
        "#98531C": "Канат",
        "#A6BF30": "Атлантида",
        "#FEBD85": "Макароны с сыром",
        "#FC6971": "Дикий арбуз",
        "#F1A7E4": "Шантильи",
        "#3DB072": "Океанический-зеленый",
        "#D86248": "Красный дамаск",
        "#C18C85": "Старая роза",
        "#6A5BCA": "Голубой маргарит",
    },
};
export default ruResources;
