/**
 * Enum с правами для простоты разработки.
 * <h2>Не для списков!</h4>
 */
var UserAuthorityEnum;
(function (UserAuthorityEnum) {
    /**
     Раздел Главного меню Редактор**/
    UserAuthorityEnum["S-EDIT-MENU"] = "S-EDIT-MENU";
    /**
     Объекты**/
    UserAuthorityEnum["S-EDIT-OBJ"] = "S-EDIT-OBJ";
    /**
     Геозоны**/
    UserAuthorityEnum["S-EDIT-GFEN"] = "S-EDIT-GFEN";
    /**
     Локация**/
    UserAuthorityEnum["S-EDIT-LOC"] = "S-EDIT-LOC";
    /**
     Устройства**/
    UserAuthorityEnum["S-EDIT-DEV"] = "S-EDIT-DEV";
    /**
     Раздел Главного меню Бизнес-правила 2.0 **/
    UserAuthorityEnum["S-BRC-MENU"] = "S-BRC-MENU";
    /**
     Бизнес-правила V 2.0 **/
    UserAuthorityEnum["S-BRC-V"] = "S-BRC-V";
    /**
     Бизнес-правила E 2.0**/
    UserAuthorityEnum["S-BRC-E"] = "S-BRC-E";
    /**
     Раздел Главного меню Бизнес-правила **/
    UserAuthorityEnum["S-BUS-MENU"] = "S-BUS-MENU";
    /**
     Бизнес-правила V**/
    UserAuthorityEnum["S-BUS-V"] = "S-BUS-V";
    /**
     Бизнес-правила E**/
    UserAuthorityEnum["S-BUS-E"] = "S-BUS-E";
    /**
     Раздел Главного меню Справочники**/
    UserAuthorityEnum["S-CAT-MENU"] = "S-CAT-MENU";
    /**
     Пользователи меню **/
    UserAuthorityEnum["S-CAT-USR"] = "S-CAT-USR";
    /**
     Пользователи**/
    UserAuthorityEnum["S-CAT-USR-E"] = "S-CAT-USR-E";
    /**
     Сотрудники меню **/
    UserAuthorityEnum["S-CAT-PERS"] = "S-CAT-PERS";
    /**
     Сотрудники V**/
    UserAuthorityEnum["S-CAT-PERS-V"] = "S-CAT-PERS-V";
    /**
     Сотрудники E**/
    UserAuthorityEnum["S-CAT-PERS-E"] = "S-CAT-PERS-E";
    /**
     Должности меню **/
    UserAuthorityEnum["S-CAT-POS"] = "S-CAT-POS";
    /**
     Должности V **/
    UserAuthorityEnum["S-CAT-POS-V"] = "S-CAT-POS-V";
    /**
     Должности E **/
    UserAuthorityEnum["S-CAT-POS-E"] = "S-CAT-POS-E";
    /**
     Структура организаций меню **/
    UserAuthorityEnum["S-CAT-ORGS"] = "S-CAT-ORGS";
    /**
     Структура организаций V**/
    UserAuthorityEnum["S-CAT-ORGS-V"] = "S-CAT-ORGS-V";
    /**
     Структура организаций E**/
    UserAuthorityEnum["S-CAT-ORGS-E"] = "S-CAT-ORGS-E";
    /**
     Носимые устройства меню **/
    UserAuthorityEnum["S-CAT-WDEV"] = "S-CAT-WDEV";
    /**
     Носимые устройства V**/
    UserAuthorityEnum["S-CAT-WDEV-V"] = "S-CAT-WDEV-V";
    /**
     Носимые устройства E**/
    UserAuthorityEnum["S-CAT-WDEV-E"] = "S-CAT-WDEV-E";
    /**
     Кнопка в Главного меню Слои**/
    UserAuthorityEnum["S-LAYM-MENU"] = "S-LAYM-MENU";
    /**
     Внешние слои**/
    UserAuthorityEnum["S-LAYM-EXT-E"] = "S-LAYM-EXT-E";
    /**
     Локальные слои**/
    UserAuthorityEnum["S-LAYM-LOC-E"] = "S-LAYM-LOC-E";
    /**
     Операции в "старой" Админке**/
    UserAuthorityEnum["S-ADM-MENU-E"] = "S-ADM-MENU-E";
    /**
     Кнопка в Главного меню Аналитическая панель**/
    UserAuthorityEnum["S-DASH-MENU"] = "S-DASH-MENU";
    /**
     Доска(по-умолчанию) V**/
    UserAuthorityEnum["S-DASH-DBRD-V"] = "S-DASH-DBRD-V";
    /**
     Доска(по-умолчанию) E**/
    UserAuthorityEnum["S-DASH-DBRD-E"] = "S-DASH-DBRD-E";
    /**
     Виджеты(по-умолчанию) V**/
    UserAuthorityEnum["S-DASH-DWDG-V"] = "S-DASH-DWDG-V";
    /**
     Виджеты(по-умолчанию) E**/
    UserAuthorityEnum["S-DASH-DWDG-E"] = "S-DASH-DWDG-E";
    /**
     Доска**/
    UserAuthorityEnum["A-DASH-CBRD-E"] = "A-DASH-CBRD-E";
    /**
     Виджеты**/
    UserAuthorityEnum["A-DASH-CWDG-E"] = "A-DASH-CWDG-E";
    /**
     История**/
    UserAuthorityEnum["S-MAPR-HIST"] = "S-MAPR-HIST";
    /**
     Тепловая карта**/
    UserAuthorityEnum["S-MAPR-HEAT"] = "S-MAPR-HEAT";
    /**
     Маршруты**/
    UserAuthorityEnum["S-MAPR-TRAC"] = "S-MAPR-TRAC";
    /**
     Кнопка в Главного меню Отчеты**/
    UserAuthorityEnum["S-RPRT-MENU"] = "S-RPRT-MENU";
    /**
     Отчет: Время нахождения в зонах по категориям**/
    UserAuthorityEnum["S-RPRT-01"] = "S-RPRT-01";
    /**
     Отчет: Общее время нахождения в зонах по датам и категориям**/
    UserAuthorityEnum["S-RPRT-02"] = "S-RPRT-02";
    /**
     Отчет: Порядок обхода зон**/
    UserAuthorityEnum["S-RPRT-03"] = "S-RPRT-03";
    /**
     Отчет: Учет общего времени пребывания сотрудников в рабочих зонах**/
    UserAuthorityEnum["S-RPRT-04"] = "S-RPRT-04";
    /**
     Отчет: Учет общего времени пребывания сотрудников в периметре строительной площадки**/
    UserAuthorityEnum["S-RPRT-05"] = "S-RPRT-05";
    /**
     Отчет: Статистика численности сотрудников по Подрядным организациям**/
    UserAuthorityEnum["S-RPRT-06"] = "S-RPRT-06";
    /**
     Отчет: Консолидированный отчет о зафиксированных нарушениях**/
    UserAuthorityEnum["S-RPRT-07"] = "S-RPRT-07";
    UserAuthorityEnum["S-RPRT-08"] = "S-RPRT-08";
    UserAuthorityEnum["S-RPRT-09"] = "S-RPRT-09";
    UserAuthorityEnum["S-RPRT-10"] = "S-RPRT-10";
    UserAuthorityEnum["S-RPRT-11"] = "S-RPRT-11";
    UserAuthorityEnum["S-RPRT-12"] = "S-RPRT-12";
    UserAuthorityEnum["S-RPRT-13"] = "S-RPRT-13";
    UserAuthorityEnum["S-RPRT-14"] = "S-RPRT-14";
    UserAuthorityEnum["S-RPRT-15"] = "S-RPRT-15";
    UserAuthorityEnum["S-RPRT-16"] = "S-RPRT-16";
    UserAuthorityEnum["S-RPRT-17"] = "S-RPRT-17";
    UserAuthorityEnum["S-RPRT-18"] = "S-RPRT-18";
    /**
     Отчет: Количество входов и время, проведённое в геозонах с бизнес-правилами **/
    UserAuthorityEnum["S-RPRT-20"] = "S-RPRT-20";
    /**
     Отчет: Зафиксированные события "Длительное нахождение на месте" **/
    UserAuthorityEnum["S-RPRT-21"] = "S-RPRT-21";
    /**
     Отчет: Длительная неподвижность и время, проведённое в геозонах с бизнес-правилами **/
    UserAuthorityEnum["S-RPRT-22"] = "S-RPRT-22";
    /**
     Отчет: по сотрудникам сменный (суточный) **/
    UserAuthorityEnum["S-RPRT-23"] = "S-RPRT-23";
    /**
     Отчет: [РКлимат] Получение и сдача носимых устройств) **/
    UserAuthorityEnum["S-RPRT-25"] = "S-RPRT-25";
    /**
     Отчет: Сотрудники, не сдавшие Носимые устройства для Р-Климат **/
    UserAuthorityEnum["S-RPRT-26"] = "S-RPRT-26";
    /**
     Отчет: Общее время нахождение в геозонах для Р-Климат **/
    UserAuthorityEnum["S-RPRT-27"] = "S-RPRT-27";
    /**
     Отчет: Консолидированный отчет о сменах для Р-Климат **/
    UserAuthorityEnum["S-RPRT-28"] = "S-RPRT-28";
    /**
     Отчет: "Время нахождения по геозонам" для Р-Климат (type=29) **/
    UserAuthorityEnum["S-RPRT-29"] = "S-RPRT-29";
    /**
     Отчет: "Количество входов и время, проведённое в геозонах с бизнес-правилами" для Р-Климат (type=30) **/
    UserAuthorityEnum["S-RPRT-30"] = "S-RPRT-30";
    /**
     Отчет: "Учет общего времени пребывания" для Р-Климат (type=31) **/
    UserAuthorityEnum["S-RPRT-31"] = "S-RPRT-31";
    /**
     Отчет: "[ММК] Cобытия газовой безопасности" (type=32) **/
    UserAuthorityEnum["S-RPRT-32"] = "S-RPRT-32";
    /**
     Кнопка в главном меню Журнал аудита**/
    UserAuthorityEnum["S-SECAUD-MENU"] = "S-SECAUD-MENU";
    /**
     Журнал аудита**/
    UserAuthorityEnum["S-SECAUD-LOG"] = "S-SECAUD-LOG";
    /**
     Техника меню **/
    UserAuthorityEnum["S-CAT-VEH"] = "S-CAT-VEH";
    /**
     Техника V**/
    UserAuthorityEnum["S-CAT-VEH-V"] = "S-CAT-VEH-V";
    /**
     Техника E**/
    UserAuthorityEnum["S-CAT-VEH-E"] = "S-CAT-VEH-E";
    /**
     Типы техники меню **/
    UserAuthorityEnum["S-CAT-VEHT"] = "S-CAT-VEHT";
    /**
     Типы техники V**/
    UserAuthorityEnum["S-CAT-VEHT-V"] = "S-CAT-VEHT-V";
    /**
     Типы техники E**/
    UserAuthorityEnum["S-CAT-VEHT-E"] = "S-CAT-VEHT-E";
    /**
     Администратор отчетов**/
    UserAuthorityEnum["S-RPRT-ADM"] = "S-RPRT-ADM";
    /**
     * Администратор регулярных отчетов
     */
    UserAuthorityEnum["S-REG-RPRT-ADMIN"] = "S-REG-RPRT-ADMIN";
    /**
     * Привязка и отвязка носимых устройств
     */
    UserAuthorityEnum["S-CAT-WDEV-DISTR-E"] = "S-CAT-WDEV-DISTR-E";
    /**
     * Привязка и отвязка носимых устройств с указанием даты и времени
     */
    UserAuthorityEnum["S-CAT-WDEV-DISTR-TIME-E"] = "S-CAT-WDEV-DISTR-TIME-E";
    /**
     * Типы обучений | Training types.
     * Подраздел в меню.
     */
    UserAuthorityEnum["S-CAT-TRT"] = "S-CAT-TRT";
    /**
     * Типы обучений | Training types.
     * Объекты справочника. Просмотр.
     */
    UserAuthorityEnum["S-CAT-TRT-V"] = "S-CAT-TRT-V";
    /**
     * Типы обучений | Training types.
     * Объекты справочника. Редактирование.
     */
    UserAuthorityEnum["S-CAT-TRT-E"] = "S-CAT-TRT-E";
    /**
     * Сертификаты | Certificates.
     * Подраздел в меню.
     */
    UserAuthorityEnum["S-CAT-CERT"] = "S-CAT-CERT";
    /**
     * Сертификаты | Certificates.
     * Объекты справочника. Просмотр.
     */
    UserAuthorityEnum["S-CAT-CERT-V"] = "S-CAT-CERT-V";
    /**
     * Сертификаты | Certificates.
     * Объекты справочника. Редактирование.
     */
    UserAuthorityEnum["S-CAT-CERT-E"] = "S-CAT-CERT-E";
})(UserAuthorityEnum || (UserAuthorityEnum = {}));
export default UserAuthorityEnum;
